// import React, {useState, useRef} from 'react';
import React, {useState, useEffect} from 'react';
import './ImageGenerator.css';
import bluedove from '../Assets/bluedove.png';
import background1 from '../Assets/background1.png';
import background2 from '../Assets/background2.png';
import background3 from '../Assets/background3.png';
import background4 from '../Assets/background4.png';
import axios from 'axios';
import whiteTshirt from '../Assets/tshirt_white.png';
import blackTshirt from '../Assets/tshirt_black.png';
import grayTshirt from '../Assets/tshirt_gray.png';
import blueTshirt from '../Assets/tshirt_blue.png';
import redTshirt from '../Assets/tshirt_red.png';

const ImageGenerator = () => {

    const [image_url, setImage_url] = useState("/")
    const [inputValue, setInputValue] = useState(''); // State for input value
    const [loading, setLoading] = useState(false);
    const [generatedImage, setGeneratedImage] = useState(null);
    const [finalImage, setFinalImage] = useState(null); // State for final saved image
    const [tshirtColor, setTshirtColor] = useState(null); // Changed initial state to null
    const [page, setPage] = useState('initial'); // State to manage different pages
    // const [prevPage, setPrevPage] = useState('initial'); // Track the previous page
    const [history, setHistory] = useState([]); // Stack to keep track of page history

    // const [showColorOptions, setShowColorOptions] = useState(false); // State to show color options

    const [imageType, setImageType] = useState('');
    const [subject, setSubject] = useState('');
    const [details, setDetails] = useState('');
    const [style, setStyle] = useState('');
    const [textPosition, setTextPosition] = useState('');

    const [text, setText] = useState('');
    const [font, setFont] = useState('');
    const [fontColor, setFontColor] = useState('');
    const [backgroundColor, setbackgroundColor] = useState('');
    const [additional, setAdditional] = useState('');

    const [prompt, setPrompt] = useState('');

        // Combine all the values into a single prompt
    const updatePrompt = () => {
      let newPrompt = '';
      if (page === 'hasText'){
        newPrompt = `${imageType} of the text "${text}" written in ${font} typeface, centered in ${fontColor} on ${backgroundColor} background. ${additional}`.trim();
      } else if (page === 'hasImage'){
        newPrompt = `${imageType} ${subject} ${details}. ${additional}`.trim();
      } else if (page === 'hasBoth'){
        newPrompt = `${imageType} ${subject} ${details}, with the text "${text}" written in ${font} typeface, centered in ${fontColor} on ${backgroundColor} background, ${textPosition}. ${additional}`.trim();
      }
      setPrompt(newPrompt);
      console.log('Generated Prompt:', newPrompt); 
    };
  
      // Use this effect to update the prompt whenever an input changes
    useEffect(() => {
        updatePrompt();
    }, [imageType, subject, text, font, fontColor, backgroundColor, additional, details, textPosition]);

    useEffect(() => {
      if (page !== 'initial') {
          setHistory(prevHistory => [...prevHistory, page]);
      }
  }, [page]);

  // Handle the final prompt generation and set it to inputValue
  const handleGeneratePrompt = () => {
    setInputValue(prompt); // This is the key step where the generated prompt is finalized
    console.log('Input Value:', inputValue); // Log inputValue
  };

// const handleGeneratePrompt = () => {
//   const generatedPrompt = `${imageType} ${subject} ${details} ${style} ${composition} ${text} ${additional}`.trim();
//   setInputValue(generatedPrompt);
// };

  const goBack = () => {
      setHistory(prevHistory => {
          const updatedHistory = [...prevHistory];
          updatedHistory.pop(); 
          const lastPage = updatedHistory.pop(); 
          setPage(lastPage || 'initial'); 
          return updatedHistory;
      });
  };

    const generateImage = async () =>{
        // if(inputRef.current.value===""){
        
    //     console.log('Generate Image button clicked');
    // console.log('Current inputValue:', inputValue); // Log current inputValue
    // console.log('Current prompt:', prompt); // Log current prompt

        if (inputValue === "" && prompt ==="") {
            return;
        }
        setLoading(true)

        const requestPrompt = page === 'describe' ? inputValue : prompt;
        if (!requestPrompt) {
          console.error('Prompt is empty');
          return;
      }
        console.log('Prompt being sent to the API:', prompt);

        try {
          const response = await fetch(
            "https://bluedovecreation-f12655b62b2c.herokuapp.com/api/generate-image",
            {
                method: "POST",
                headers:{
                    "Content-Type": "application/json",
                },
                body:JSON.stringify({image_request:{
                    // prompt: inputValue,
                    prompt: requestPrompt,  // Use the `prompt` state directly here
                    // num_images: 1, // Number of images to generate
                    // aspect_ratio: 'ASPECT_10_16',
                    // seed: 12345,
                    // negative_prompt: 'brush strokes, painting',
                    // magic_prompt_option: 'ON',
                    model: 'V_2_TURBO',
                }
                }),
            }
         );
         if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        console.log('Generated image data:', data); // Log the response data
        const imageUrl = data.data[0].url;
        // const imageUrl = `https://${req.headers.host}/uploads/${file.filename}`;
        
        console.log('Generated image URL:', imageUrl); // Log the image URL
        setGeneratedImage(imageUrl);
        setImage_url(imageUrl);
        } catch (error){
        console.error('Error generating image:', error);
        } finally {
        setLoading(false)
        }
    }

    const saveImage = async () => {
        if (!generatedImage) return;
        try {
        //   const response = await axios.get('https://bluedovecreation-f12655b62b2c.herokuapp.com/fetch-image', {
        //     params: { url: generatedImage },
        //     responseType: 'blob'
        //   });
        //   const blob = response.data;
        //   const file = new File([blob], 'generated-image.png', { type: blob.type });
        //   const formData = new FormData();
        //   formData.append('file', file);

        //   const uploadResponse = await axios.post('https://bluedovecreation-f12655b62b2c.herokuapp.com/upload', formData, {
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //   }
        //   });
        // console.log('Saved Image URL:', uploadResponse.data.imageUrl);
        setFinalImage(generatedImage); // Store the final image URL
        // setShowColorOptions(true); // Show color options after saving
        setPage('selectColor'); // Navigate to the color selection page
        // navigateToPage('selectColor');
        } catch (error) {
          console.error('Error saving the image:', error);
        }
      };

    const tshirtTemplates = {
        white: whiteTshirt,
        black: blackTshirt,
        gray: grayTshirt,
        blue: blueTshirt,
        red: redTshirt
      };

    const colorOptions = [
      { name: 'Red', color: 'red' },
      { name: 'Blue', color: 'blue' },
      { name: 'White', color: 'white' },
      { name: 'Gray', color: 'gray' },
      { name: 'Black', color: 'black' },
    ];

    const orderTshirt = () => {
      alert('Order placed for your custom T-shirt!');
      // You can replace this with your order handling logic
    };


  return (
    <div className='ai-image-generator'>

      {page === 'initial' && (
          <>
              <div className="header">Create your <span>design</span></div>
              <div className="button-container">
                  <button className="option-btn" onClick={() => setPage('describe')}>
                      Describe my design by myself
                  </button>
                  <button className="option-btn" onClick={() => setPage('help')}>
                      I need help to describe what I want
                  </button>
              </div>
          </>
      )}

      {page === 'describe' && (
        <div id="describe-section">
            <button className="back-btn" onClick={goBack}>
                ← Back
            </button>
            <div> 
                <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe What You Want To See' rows="4"/>
                <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
            </div>

            <div className="img-loading">
            <div className="loading">
                    <div className={loading?"loading-bar-full":"loading-bar"}></div>
                    <div className={loading?"loading-text":"display-none"}>Loading...</div>
                </div>
                <div className="image"><img src={image_url==="/"?background3:image_url} alt=""/>
                </div>
            </div>
            {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )}
        </div>
      )}

      {page === 'help' && (
        <>
          <button className="back-btn" onClick={goBack}>
            ← Back
          </button>
          <div className="button-container">
              <button className="option-btn" onClick={() => setPage('hasBoth')}>
                  My design has both images and text
              </button>
              <button className="option-btn" onClick={() => setPage('hasImage')}>
                  My design only has some images
              </button>
              <button className="option-btn" onClick={() => setPage('hasText')}>
                  My design only has some text
              </button>
          </div>
          </>
      )}  
      {/* New pages for each option */}
      {page === 'hasBoth' && (
        <>
          <button className="back-btn" onClick={goBack}>
              ← Back
          </button>
          <div className="prompt-generator">
            <h2>Prompt Generator</h2>


            <div className="tag-container">
              <div className="tag-input-select">
              <select value={imageType} onChange={(e) => setImageType(e.target.value)}>
                  <option value="">Select...</option>
                  <option value="An image of">An image of</option>
                  <option value="A sketch of">A sketch of</option>
                  <option value="A photograph of">A photograph of</option>
                  <option value="A 3D rendering of">A 3D rendering of</option>
                  <option value="An illustration of">An illustration of</option>
                  <option value="A drawing of">A drawing of</option>
                  <option value="A picture of">A picture of</option>
                  <option value="A cubist painting of">A cubist painting of</option>
              </select>
            </div>

            <div className="tag-input">
                <input 
                    type="text" 
                    placeholder="Subject" 
                    value={subject} 
                    onChange={(e) => { setSubject(e.target.value); updatePrompt(); }} 
                />
            </div>
            <div className="tag-input">
                <input 
                    type="text" 
                    placeholder="Details" 
                    value={details} 
                    onChange={(e) => { setDetails(e.target.value); updatePrompt(); }}
                />
            </div>
            <div className="tag-input-text">
                    <input 
                        type="text" 
                        placeholder="Text" 
                        value={text} 
                        onChange={(e) => { setText(e.target.value); updatePrompt(); }}
                    />
                </div>
                {/* <div className="tag-container-inline"> */}
                <div className="tag-input">
                    <input 
                        type="text" 
                        placeholder="Font" 
                        value={font} 
                        onChange={(e) => { setFont(e.target.value); updatePrompt(); }} 
                    />
                </div>
                <div className="tag-input">
                    <input 
                        type="text" 
                        placeholder="Font Color" 
                        value={fontColor} 
                        onChange={(e) => { setFontColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input">
                    <input 
                        type="text" 
                        placeholder="Background Color" 
                        value={backgroundColor} 
                        onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input-additional">
                    <input 
                        type="text" 
                        placeholder="Addtional description" 
                        value={additional} 
                        onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                    />
                </div>
            </div>
          </div>


          <div className="prompt-display">
                <textarea value={prompt} readOnly rows="4" />
            </div>

          <div> 
              {/* <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe What You Want To See' rows="4"/> */}
              <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
          </div>



          {/* <button className="prompt-ubtn" onClick={handleGeneratePrompt}>Generate Prompt</button>

          <div> 
              <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe What You Want To See' rows="4"/>
              <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
          </div> */}

          <div className="img-loading">
            <div className="loading">
                <div className={loading?"loading-bar-full":"loading-bar"}></div>
                <div className={loading?"loading-text":"display-none"}>Loading...</div>
            </div>
            <div className="image"><img src={image_url==="/"?background3:image_url} alt=""/>
            </div>
          </div>

          {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )}  
        </>
      )}

      {page === 'hasImage' && (
        <>
          <button className="back-btn" onClick={goBack}>
              ← Back
          </button>
          <div className="prompt-generator">
            <h2>Prompt Generator</h2>



            <div className="tag-container">
              <div className="tag-input-select">
              <select value={imageType} onChange={(e) => setImageType(e.target.value)}>
                  <option value="">Select...</option>
                  <option value="An image of">An image of</option>
                  <option value="A sketch of">A sketch of</option>
                  <option value="A photograph of">A photograph of</option>
                  <option value="A 3D rendering of">A 3D rendering of</option>
                  <option value="An illustration of">An illustration of</option>
                  <option value="A drawing of">A drawing of</option>
                  <option value="A picture of">A picture of</option>
                  <option value="A cubist painting of">A cubist painting of</option>
              </select>
            </div>

            <div className="tag-input">
                <input 
                    type="text" 
                    placeholder="Subject" 
                    value={subject} 
                    onChange={(e) => { setSubject(e.target.value); updatePrompt(); }} 
                />
            </div>
            <div className="tag-input">
                <input 
                    type="text" 
                    placeholder="Details" 
                    value={details} 
                    onChange={(e) => { setDetails(e.target.value); updatePrompt(); }}
                />
            </div>
            <div className="tag-input">
                    <input 
                        type="text" 
                        placeholder="Background Color" 
                        value={backgroundColor} 
                        onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                    />
                </div>
            {/* <div className="tag-input">
                <input 
                    type="text" 
                    placeholder="Style" 
                    value={style} 
                    onChange={(e) => { setStyle(e.target.value); updatePrompt(); }}
                />
            </div> */}
            <div className="tag-input-additional">
                    <input 
                        type="text" 
                        placeholder="Addtional description" 
                        value={additional} 
                        onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                    />
                </div>
          </div>
      
          {/* <button className="prompt-ubtn" onClick={handleGeneratePrompt}>Generate Prompt</button> */}
          </div>

          <div className="prompt-display">
                <textarea value={prompt} readOnly rows="4" />
            </div>

          <div> 
              {/* <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe What You Want To See' rows="4"/> */}
              <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
          </div>

          <div className="img-loading">
          <div className="loading">
              <div className={loading?"loading-bar-full":"loading-bar"}></div>
              <div className={loading?"loading-text":"display-none"}>Loading...</div>
          </div>
              <div className="image"><img src={image_url==="/"?background3:image_url} alt=""/>
              </div>
          </div>

          {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )}  
        </>
      )}

      {page === 'hasText' && (
        <>
          <button className="back-btn" onClick={goBack}>
              ← Back
          </button>
          <div className="prompt-generator">
            <h2>Prompt Generator</h2>


            <div className="tag-container">
                <div className="tag-input-select">
                    <select value={imageType} onChange={(e) => setImageType(e.target.value)}>
                        <option value="A typography">A typography </option>
                        <option value="An illustration">An illustration </option>
                        <option value="A typography design">A typography design </option>
                        <option value="A typography illustration">A typography illustration </option>
                    </select>
                </div>
                {/* <span> of the text " </span> Fixed text */}
                <div className="tag-input-text">
                    <input 
                        type="text" 
                        placeholder="Text" 
                        value={text} 
                        onChange={(e) => { setText(e.target.value); updatePrompt(); }}
                    />
                </div>
                {/* <div className="tag-container-inline"> */}
                <div className="tag-input">
                    <input 
                        type="text" 
                        placeholder="Font" 
                        value={font} 
                        onChange={(e) => { setFont(e.target.value); updatePrompt(); }} 
                    />
                </div>
                <div className="tag-input">
                    <input 
                        type="text" 
                        placeholder="Font Color" 
                        value={fontColor} 
                        onChange={(e) => { setFontColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input">
                    <input 
                        type="text" 
                        placeholder="Background Color" 
                        value={backgroundColor} 
                        onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                    />
                </div>
                <div className="tag-input-additional">
                    <input 
                        type="text" 
                        placeholder="Addtional description" 
                        value={additional} 
                        onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                    />
                </div>
            </div>

            {/* <div className="generate-btn" onClick={generateImage}>Generate an image</div>  */}
           
          </div>
      
          <div className="prompt-display">
                <textarea value={prompt} readOnly rows="4" />
            </div>

          {/* <button className="prompt-ubtn" onClick={handleGeneratePrompt}>Generate Prompt</button> */}

          <div> 
              {/* <textarea id="description-textarea" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Describe What You Want To See' rows="4"/> */}
              <div className="generate-btn" onClick={generateImage}>Generate an image</div> 
          </div>

          <div className="img-loading">
          <div className="loading">
              <div className={loading?"loading-bar-full":"loading-bar"}></div>
              <div className={loading?"loading-text":"display-none"}>Loading...</div>
            </div>
            
              <div className="image"><img src={image_url==="/"?background3:image_url} alt=""/>
              </div>
          </div>

          {generatedImage && (<div className="save-btn" onClick={saveImage}>Select the image</div> )}  
        </>
      )}

      {page === 'selectColor' && finalImage && (
        <>
        {/* <div className="next-page"> */}
          <div className="color-picker">
            <div className="select-tshirt-header">Select T-shirt Color:</div>
            <div className="color-options">
              {colorOptions.map((option) => (
                  <button key={option.name} style={{ backgroundColor: option.color }} className="color-option" onClick={() => setTshirtColor(option.color)} aria-label={option.name}>
                  </button>
              ))}
            </div>
          </div>
      
          {tshirtColor && (
            <div className="tshirt-preview-container">
              <div className="tshirt-preview" style={{ backgroundImage: `url(${tshirtTemplates[tshirtColor]})` }}>
                <img src={finalImage} alt="T-shirt design" className="tshirt-image"/>
              </div>
              <div className="order-btn-container">
                <button className="order-btn" onClick={orderTshirt}>Order</button>
              </div>  
            </div>
            )
          }
     </>
        // </div>
      )}
    </div>
  )
}
export default ImageGenerator
